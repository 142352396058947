import Link from "next/link";
import Image from "next/image";

interface ICard {
  link: string;
  image: string;
  imageAlt: string;
  title: string;
  description: string;
}

export default function Card({
  link,
  image,
  imageAlt,
  title,
  description,
}: ICard) {
  return (
    <Link
      href={link}
      className="flex flex-col border border-slate-500 bg-slate-800 rounded-md p-4 m-2 hover:border-amber-600 hover:text-amber-600 items-center md:w-1/4"
    >
      <Image src={image} alt={imageAlt} width="250" height="250" />

      <div className="text-xl">{title}</div>

      <p className="py-4">{description}</p>
    </Link>
  );
}
