import Image from "next/image";
import Card from "../components/common/card/card";

import { ALL_TOOLS, ALL_GUIDES } from "../components/constants";

export default function Home() {
  return (
    <div>
      <main className="w-full flex flex-col items-center">
        <h1 className="px-4 text-4xl">
          Welcome to <span className="text-amber-500">Money Meister!</span>
        </h1>

        <Image
          src="/logo.webp"
          alt="money meister logo"
          width="200"
          height="200"
        />

        <p className="text-xl m-4">
          Money management made easy. Our finance tools equip you with all the
          knowledge and guidance you need to grow your money and building your
          savings.
        </p>

        <div className="flex flex-wrap w-3/4">
          {[...ALL_TOOLS, ...ALL_GUIDES].map((tool) => (
            <Card
              link={tool.url}
              image={tool.img}
              imageAlt={tool.alt}
              title={tool.name}
              description={tool.description}
            />
          ))}
        </div>
      </main>
    </div>
  );
}
